import React from 'react';
import App from './App';
import 'antd/dist/antd.min.css';
import './global.scss';

import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

serviceWorker.unregister();
