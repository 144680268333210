import { Spin } from 'antd';
import React, { ComponentType, Suspense, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Subscription } from 'rxjs';

import { IMasterRouter, IRoute } from 'types/Route';
import { AsyncStorageUtils, StorageKey } from 'utils/async-storage.utils';
import { EventBusName } from 'utils/event-bus';
import EventBus from 'utils/event-bus/event-bus';
import { PATH_AUTH_LOGIN } from '.';

const MasterRoutes = (props: IMasterRouter) => {
    const routes = props.routes;
    const history = useHistory();
    const subscriptions = useRef<any>();
    useEffect(() => {
        if (
            window.location.pathname.split('/')[1] !== 'auth' &&
            !AsyncStorageUtils.get(StorageKey.TOKEN)
        ) {
            history.push(PATH_AUTH_LOGIN);
        }

        registerEventBus();

        return () => {
            unregisterEventBus();
        };
        // eslint-disable-next-line
    }, []);

    const registerEventBus = () => {
        subscriptions.current = new Subscription();
        subscriptions.current.add(
            EventBus.getInstance().events.subscribe((data: any) => {
                switch (data.type) {
                    case EventBusName.LOGOUT_EVENT:
                        history.push('/auth/login');
                        break;

                    default:
                        break;
                }
            })
        );
    };

    const unregisterEventBus = () => {
        subscriptions.current?.unsubscribe();
    };
    return (
        <Switch>
            {routes.map((route: IRoute, index: number) => {
                return (
                    <Route
                        path={route.path}
                        exact={route.exact}
                        component={() =>
                            WaitingComponent(route.component, route.routes, route.componentFallback)
                        }
                        key={index}
                        strict={!!route.strict}
                    />
                );
            })}
            <Redirect to="/" />
        </Switch>
    );
};

const WaitingComponent = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Component: React.LazyExoticComponent<ComponentType<any>>,
    routes: Array<IRoute> = [],
    ComponnetFallback?: React.FC
) => {
    // console.log('MasterRoutes', routes);

    return (
        <Suspense
            fallback={
                ComponnetFallback ? (
                    <ComponnetFallback />
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            marginTop: 100,
                            alignContent: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Spin />
                    </div>
                )
            }
        >
            <Component routes={routes} />
        </Suspense>
    );
};

export default MasterRoutes;
