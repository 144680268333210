
/* 
  Created by Hoàng Đức Thuận at 06-28-2022 15:42:22
  Module login
*/
import { ShopOutlined } from '@ant-design/icons';
import { QR_CODE } from 'constants/path';
import { lazy } from 'react';
import { IRoute } from 'types/Route';
import React from 'react';

const ManageGarageScreen = lazy(
    () => import('modules/QRCodeManagement/QRCodeManagement.screen')
);

const ContentLayout = lazy(
    () => import('../../libraries/layouts/content.layout')
);

const ManageGarageRoute: IRoute = {
    path: QR_CODE,
    name: 'product.menu',
    component: ContentLayout,
    icon: <ShopOutlined />,
    routes: [
        {
            path: QR_CODE,
            name: 'product.menu',
            component: ManageGarageScreen,
            exact: true
        },
    ]
};

export default ManageGarageRoute;

