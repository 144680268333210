import { HomeOutlined } from '@ant-design/icons';
import React, { lazy } from 'react';
import { IRoute } from 'types/Route';
import { PATH_HOME } from '.';
import * as routes from './routes';
import LabelManagerRoute from './routes/label-manager.route';
import QRCodeManagementRoute from './routes/QRCodeManagement.route';
import RollManagerRoute from './routes/roll-manager.route';


const MainLayout = lazy(() => import('../libraries/layouts/main.layout'));
const HomeScreen = lazy(() => import('../modules/home/home.screen'));

const RouteConfigs: IRoute[] = [
    routes.AuthRoute,
    {
        path: PATH_HOME,
        name: 'menu_home',
        component: MainLayout,
        hideInMenu: true,
        routes: [
            {
                path: PATH_HOME,
                name: 'menu_home',
                icon: <HomeOutlined />,
                exact: true,
                component: HomeScreen,
            },
            // LoginRoute,
            QRCodeManagementRoute,
            LabelManagerRoute,
            RollManagerRoute
            
            // routes.SettingRoute,
            // routes.FooterRoute,
            // routes.BannerRoute
        ]
    }
];

export default RouteConfigs;
