import { default as React, Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { setI18nConfig } from 'res/languages';
import MasterRoutes from 'routing/master.route';
import RouteConfigs from 'routing/config.route';
import BaseConfirmModal from 'libraries/modal/BaseConfirmModal';

setI18nConfig();

const App: React.FC<any> = () => {
    return (
        <Fragment>
            {/* @ts-ignore */}
            <Router>
                <MasterRoutes routes={RouteConfigs} />
            </Router>

            <BaseConfirmModal />
        </Fragment>
    );
};

export default App;
