import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { memo, useEffect, useRef, useState } from 'react';
import { translate } from 'res/languages';
import { Subscription } from 'rxjs';
import { BaseConfirmModalParams } from 'types';
import { EventBusName } from 'utils/event-bus';
import EventBus from 'utils/event-bus/event-bus';

function BaseConfirmModal() {
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState<BaseConfirmModalParams>();

    const subscriptions = useRef<any>();

    useEffect(() => {
        registerEventBus();

        return () => {
            unregisterEventBus();
        };
    }, []);

    const registerEventBus = () => {
        subscriptions.current = new Subscription();
        subscriptions.current.add(
            EventBus.getInstance().events.subscribe((data: any) => {
                switch (data.type) {
                    case EventBusName.SHOW_MODAL_CONFIRM_EVENT:
                        handleShowModal(data.payload);
                        break;
                    case EventBusName.HIDE_MODAL_CONFIRM_EVENT:
                        handleCancel();
                        break;
                    case EventBusName.HIDE_LOADING_CONFIRM_EVENT:
                        setLoading(false);
                        break;
                    default:
                        break;
                }
            })
        );
    };

    const handleShowModal = (payload: BaseConfirmModalParams) => {
        setParams(payload);
        setVisible(true);
    };

    const unregisterEventBus = () => {
        subscriptions.current?.unsubscribe();
    };

    const handleOk = () => {
        setLoading(true);
        params?.onOk();
    };

    const handleCancel = () => {
        setVisible(false);
        setLoading(false);
        setParams(undefined);
    };

    const renderDescription = (): any => {
        if (params?.contentHtml) {
            return <div dangerouslySetInnerHTML={{ __html: params?.contentHtml }} />;
        }

        return translate(params?.content, params?.contentParams);
    };

    return (
        <Modal
            visible={!!params && visible}
            title={translate(params?.title || 'shared.alert')}
            onOk={handleOk}
            onCancel={handleCancel}
            width={500}
            style={{ zIndex: 1000000000000 }}
            footer={[
                <Button size="large" key="back" ghost type="primary" onClick={handleCancel}>
                    {translate('cancel_modal_confirm')}
                </Button>,
                <Button
                    size="large"
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                >
                    {translate('ok_modal_confirm')}
                </Button>
            ]}
        >
            <div style={{ margin: '20px 0px' }}>{renderDescription()}</div>
        </Modal>
    );
}

export default memo(BaseConfirmModal);
