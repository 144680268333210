import i18n from 'i18n-js';
import memoize from 'lodash.memoize';
import vi from './translations/vi';
import en from './translations/en';
import { AsyncStorageUtils, StorageKey } from 'utils/async-storage.utils';

const translationGetters: any = {
    vi: () => vi,
    en: () => en
};

export const translate: any = memoize(
    (key: any, config?: any) => i18n.t(key, config),
    (key: any, config?: any) => (config ? key + JSON.stringify(config) : key)
);

export const setI18nConfig = (locale?: string): void => {
    const storeCode = AsyncStorageUtils.get(StorageKey.LOCACLE);
    let langCode;

    if (locale) {
        langCode = locale;
        AsyncStorageUtils.save(StorageKey.LOCACLE, locale);
    }
    if (storeCode && storeCode !== 'undefined' && storeCode !== 'null') {
        langCode = storeCode;
    }
    if (!langCode) langCode = 'vi';
    // clear translation cache
    translate.cache.clear();

    // set i18n-js config
    i18n.translations = {
        [langCode]: translationGetters[langCode]()
    };
    i18n.locale = langCode;
};

export const currentLocale = (): string => {
    return i18n.locale;
};

export const isEnglish = (): boolean => {
    return i18n.locale === 'en';
};
