export interface BaseEvent {
    type: EventBusName;
}

export interface BaseEventPayload<Payload> {
    type: EventBusName;
    payload: Payload;
}

export enum EventBusName {
    LOGOUT_EVENT,
    SHOW_MODAL_CONFIRM_EVENT,
    SHOW_MODAL_ADD_CATEGORY_EVENT,
    HIDE_MODAL_CONFIRM_EVENT,
    HIDE_LOADING_CONFIRM_EVENT,
    SHOW_MODAL_ADD_BANNER_EVENT
}
