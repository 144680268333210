export default {
    ...require('./vi.shared.json'),
    ...require('../../modules/QRCodeManagement/languages/vi.manageGarage.json'),
    ...require('../../modules/auth/res/translations/vi.auth.json'),
    ...require('../../modules/label-manager/languages/vi.label-manager.json'),
    ...require('../../modules/roll-manager/languages/vi.roll-manager.json'),
    
};


