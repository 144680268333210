
/* 
  Created by Hoàng Đức Thuận at 06-28-2022 15:42:22
  Module login
*/
import { ExceptionOutlined } from '@ant-design/icons';
import { LABEL_MANAGER_PATH} from 'constants/path';
import { lazy } from 'react';
import { IRoute } from 'types/Route';
import React from 'react';

const LabelManager = lazy(
    () => import('modules/label-manager/label-manager.screen')
);

const ContentLayout = lazy(
    () => import('../../libraries/layouts/content.layout')
);

const LabelManagerRoute: IRoute = {
    path: LABEL_MANAGER_PATH,
    name: 'label.menu',
    component: ContentLayout,
    icon: <ExceptionOutlined />,
    routes: [
        {
            path: LABEL_MANAGER_PATH,
            name: 'label.menu',
            component: LabelManager,
            exact: true
        },
    ]
};

export default LabelManagerRoute;

